import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// Component imports
import { Spinner } from "./components/Spin";
// import MaxiFairShortDesc from "views/MaxiFair/MaxiFairShortDesc";

// Lazy-loaded components
const Landing = lazy(() => import("views/Landing"));
const Alumni = lazy(() => import("./views/Alumni"));
const MaxiFair = lazy(() => import("./views/MaxiFair/MaxiFair"));
const News = lazy(() => import("./views/News"));
const Team = lazy(() => import("./views/Team"));
const Sponsors2024 = lazy(() => import("./views/Sponsors"));
const Schedule2024 = lazy(() => import("./views/Schedule2024"));
const ArtAttack = lazy(() => import("./views/ArtAttack"));
const MissJamshedpur = lazy(() => import("./views/MissJamshedpur"));
const MasterChef = lazy(() => import("./views/MasterChef"));
const FavoriteFamily = lazy(() => import("./views/FavoriteFamily"));
const SportsMania = lazy(() => import("./views/SportsMania"));
const FancyDress = lazy(() => import("./views/FancyDress"));
const DanceMania = lazy(() => import("./views/DanceMania"));
const JsrQuiz = lazy(() => import("./views/JsrQuiz"));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again later.</h1>;
    }
    return this.props.children;
  }
}

const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/landing" element={<Landing />} />
            {/* <Route path="/fairDescription" element={<MaxiFairShortDesc />} /> */}
            <Route path="/alumni" element={<Alumni />} />
            <Route path="/team" element={<Team />} />
            <Route path="/news" element={<News />} />
            <Route path="/fair" element={<MaxiFair />} />
            <Route path="/fair/sponsors" element={<Sponsors2024 />} />
            <Route path="/fair/schedule" element={<Schedule2024 />} />
            <Route path="/events/fair2024/art_attack" element={<ArtAttack />} />
            <Route path="/events/fair2024/favorite_family" element={<FavoriteFamily />} />
            <Route path="/events/fair2024/dance_mania" element={<DanceMania />} />
            <Route path="/events/fair2024/sports_mania" element={<SportsMania />} />
            <Route path="/events/fair2024/fancy_dress" element={<FancyDress />} />
            <Route path="/events/fair2024/master_chef" element={<MasterChef />} />
            <Route path="/events/fair2024/miss_jamshedpur" element={<MissJamshedpur />} />
            <Route path="/events/fair2024/jamshedpur_quiz" element={<JsrQuiz />} />
            <Route path="/" element={<Landing />} />
            <Route path="*" element={<Navigate to="/fair" replace />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
