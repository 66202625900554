
import ReactDOM from "react-dom";
import App from "./App";
import ReactGA from 'react-ga';
import "assets/styles/tailwind.css";

const TRACKING_ID = "UA-44560502-2";

ReactGA.initialize(TRACKING_ID);

ReactDOM.render(<App />, document.getElementById("root"));
